// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CategoryNav from "examples/Navbars/CategoryNav";

import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Tabs,
  TimePicker,
  Upload,
} from "antd";
import { _reject_restaurant } from "common/axios_services";
import { _suspend_restaurant } from "common/axios_services";
import { _approve_restaurant } from "common/axios_services";
import { openNotificationWithIcon } from "common/general-util";
import moment from "moment";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { setCount } from "splices/updateCountSplice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Footer from "examples/Footer";
import { _get_restaurant_by_id } from "common/axios_services";
import { _get_res_menus } from "common/axios_services";
import FoodCard from "components/Restaurant/FoodCard";
import { _get_users } from "common/axios_services";
import { _assign_sm } from "common/axios_services";

const SelectedRestaurantById = () => {
  const timeFormat = "mm:ss";
  const openTimeFormat = "HH:mm";
  const { TabPane } = Tabs;
  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const newAntIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#008060" }} spin />
  );
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [loadingSelectedRes, setLoadingSelectedRes] = useState(true);
  const [selectedRestaurantToggle, setSelectedRestaurantToggle] =
    useState(false);
  const [approvingRestaurant, setApprovingRestaurant] = useState(false);
  const [approvalError, setApprovalError] = useState(null);
  const dispatch = useDispatch();
  const updateCount = useSelector((state) => state?.updateCount?.count);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [menus, setMenus] = useState([]);
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(10);
  const [menuTotal, setMenuTotal] = useState(0);
  const [query, setQuery] = useState("");
  const [supportManagers, setSupportManagers] = useState([]);
  const [smPage, setSmPage] = useState(1);
  const [page_size, setPage_size] = useState(10);
  const [total_users, setTotal_users] = useState(0);
  const [assignError, setAssignError] = useState(null);
  const [assigningRestaurant, setAssigningRestaurant] = useState(false);
  const [selectedSupportManager, setSelectedSupportManager] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    id: ""
  });

  const GetRestaurantDetails = async () => {
    try {
      setLoadingSelectedRes(true);
      const restaurant_by_id = await _get_restaurant_by_id(params.id);
      setSelectedRestaurant(restaurant_by_id.data);
      let response = restaurant_by_id.data;
      form.setFieldsValue({
        name: response?.name,
        description: response?.description,
        address: response?.address,
        state: response?.state,
        lga: response?.city,
        instagram_handle: response?.instagram,
        contact: response?.contact_name,
        cooking_area: response?.cooking_area ? true : false,
        contactNumber: `${response?.contact_phone_code ? response?.contact_phone_code : "234"
          }${response?.contact_phone}`,
        contact_email: response?.contact_email,
        min_ready_time: response?.min_ready_time
          ? moment(response?.min_ready_time, timeFormat)
          : null,
        max_ready_time: response?.max_ready_time
          ? moment(response?.max_ready_time, timeFormat)
          : null,
        open_time: response?.open_time
          ? moment(response?.open_time, openTimeFormat)
          : null,
        close_time: response?.close_time
          ? moment(response?.close_time, openTimeFormat)
          : null,
      });
      if (response && response?.kitchen_images?.length > 0) {
        const mappedFileList = response.kitchen_images.map((url, index) => ({
          uid: `${url?.id}`,
          name: `cooking_area-${url?.id}`,
          status: "done",
          thumbUrl: url?.url,
          url: url?.url,
        }));
        setFileList(mappedFileList);
      } else if (response && response.kitchen_images.length === 0) {
        setFileList([]);
      }
      if (response) {
        GetRestaurantMenus(response.id);
      }
    } catch (err) {
      setSelectedRestaurant(null);
      if (err.response) {
        setApprovalError(err.response.data.message);
        openNotificationWithIcon(
          "error",
          err.response.data.title,
          err.response.data.message
        );
      } else {
        setApprovalError(err.message);
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setLoadingSelectedRes(false);
    }
  };

  const GetRestaurantMenus = async (id) => {
    try {
      setLoadingSelectedRes(true);
      const get_menus = await _get_res_menus({
        id,
        search: "",
        page,
        per_page,
      });
      setMenus(get_menus.data.data);
      setMenuTotal(get_menus.data.meta.total);
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_admin_token");
          navigate("/");
        }
        openNotificationWithIcon("error", err.response.data.message);
      } else {
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setLoadingSelectedRes(false);
    }
  };

  useEffect(() => {
    GetRestaurantDetails();
  }, [updateCount]);

  useEffect(() => {
    getUsers();
  }, [query]);

  const ApproveRestaurant = async () => {
    try {
      setApprovingRestaurant(true);
      const approve_restaurant = await _approve_restaurant(
        selectedRestaurant?.id
      );
      openNotificationWithIcon(
        "success",
        approve_restaurant.data.title,
        approve_restaurant.data.message
      );
      setSelectedRestaurantToggle(false);
      setApprovalError(null);
      dispatch(setCount(updateCount + 1));
    } catch (err) {
      if (err.response) {
        setApprovalError(err.response.data.message);
        openNotificationWithIcon(
          "error",
          err.response.data.title,
          err.response.data.message
        );
      } else {
        setApprovalError(err.message);
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setApprovingRestaurant(false);
    }
  };
  const RejectRestaurant = async () => {
    try {
      setApprovingRestaurant(true);
      const reject_restaurant = await _reject_restaurant(
        selectedRestaurant?.id
      );
      openNotificationWithIcon(
        "success",
        reject_restaurant.data.title,
        reject_restaurant.data.message
      );
      setSelectedRestaurantToggle(false);
      setApprovalError(null);
      dispatch(setCount(updateCount + 1));
    } catch (err) {
      if (err.response) {
        setApprovalError(err.response.data.message);
        openNotificationWithIcon(
          "error",
          err.response.data.title,
          err.response.data.message
        );
      } else {
        setApprovalError(err.message);
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setApprovingRestaurant(false);
    }
  };
  const SuspendRestaurant = async () => {
    try {
      setApprovingRestaurant(true);
      const suspend_restaurant = await _suspend_restaurant(
        selectedRestaurant?.id
      );
      openNotificationWithIcon(
        "success",
        suspend_restaurant.data.title,
        suspend_restaurant.data.message
      );
      setSelectedRestaurantToggle(false);
      setApprovalError(null);
      dispatch(setCount(updateCount + 1));
    } catch (err) {
      if (err.response) {
        setApprovalError(err.response.data.message);
        openNotificationWithIcon(
          "error",
          err.response.data.title,
          err.response.data.message
        );
      } else {
        setApprovalError(err.message);
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setApprovingRestaurant(false);
    }
  };

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };

  const onShowSizeChange = (current, size) => {
    setPerPage(size);
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPerPage(pageSize);
  };

  const getUsers = async () => {
    try {
      const get_users = await _get_users({
        page: smPage,
        page_size,
        category: "sm",
        query,
        verified: "",
        filterDate: "",
        filterToDate: "",
        tier: "",
      });
      setTotal_users(get_users.data.meta.total);
      setSupportManagers(get_users.data.data);
    } catch (err) {
      setSupportManagers([]);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          navigate("/");
          openNotificationWithIcon("error", err.response.data.message);
        } else {
          openNotificationWithIcon("error", err.response.data.message);
        }
      } else {
        openNotificationWithIcon("error", err.message);
      }
    }
  };

  useEffect(() => {
    if (supportManagers.length) {
      let totalSupport = supportManagers;
      let selectedSupport = {};
      totalSupport.filter(support => {
        if (support.id === selectedRestaurant?.sm_user_id) {
          selectedSupport = support;
        }
      });
      setSelectedSupportManager({
        firstName: selectedSupport.first_name,
        lastName: selectedSupport.last_name,
        email: selectedSupport.email,
        phone: selectedSupport.phone_number,
        id: selectedSupport.id
      })
    }
  }, [selectedRestaurant?.sm_user_id, supportManagers.length])

  const AssignSupportManager = async (values) => {
    let support = values.support_manager_id.split("----");
    let managerId = support[0];
    try {
      setAssigningRestaurant(true);
      const approve_restaurant = await _assign_sm(
        selectedRestaurant?.id,
        managerId
      );
      openNotificationWithIcon(
        "success",
        approve_restaurant.data.title,
        approve_restaurant.data.message
      );
      setSelectedRestaurantToggle(false);
      setAssignError(null);
      setSelectedSupportManager({
        firstName: support[1],
        lastName: support[2],
        email: support[3],
        phone: support[4],
        id: support[0]
      });
      //   navigate(0);
      dispatch(setCount(updateCount + 1));
    } catch (err) {
      if (err.response) {
        setAssignError(err.response.data.message);
        openNotificationWithIcon(
          "error",
          err.response.data.title,
          err.response.data.message
        );
      } else {
        setAssignError(err.message);
        openNotificationWithIcon("error", err.message);
      }
    } finally {
      setAssigningRestaurant(false);
    }
  };

  const SearchSupportManagers = (query) => {
    setQuery(query);
  };

  const handleSupportManagerPageChange = (page, pageSize) => {
    setSmPage(page);
    setPage_size(pageSize);
  };

  return (
    <>
      <DashboardLayout>
        <CategoryNav
          title={`${decodeURIComponent(params?.name)}`}
          breadroute="restaurant"
        />
        <MDBox pt={6} pb={3}>
          <Spin tip="Loading..." spinning={loadingSelectedRes}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {selectedRestaurant?.name} Details
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3} px={2}>
                    <div style={{ marginBottom: "3rem" }}>
                      <Tabs
                        type="card"
                        activeKey={activeTabKey}
                        onChange={(key) => setActiveTabKey(key)}
                      >
                        <TabPane tab="Restaurant Profile" key="1">
                          {approvalError ? <p>{approvalError}</p> : ""}
                          <Form
                            layout="vertical"
                            form={form}
                            style={{ padding: "20px" }}
                          >
                            <p className="profile_category_title">
                              Restaurant basic information
                            </p>
                            <div className="form-flex-2">
                              <Form.Item
                                label="Name (as to be displayed)"
                                name="name"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your restaurant name!",
                                  },
                                ]}
                              >
                                <Input
                                  disabled
                                  type="text"
                                  style={{ height: "3rem" }}
                                />
                              </Form.Item>
                              <Form.Item label="Contact" name="contact">
                                <Input
                                  disabled
                                  type="text"
                                  style={{ height: "3rem" }}
                                />
                              </Form.Item>
                            </div>

                            <div className="form-flex-2">
                              <Form.Item
                                label="Contact phone"
                                name="contactNumber"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your restaurant contact phone!",
                                  },
                                ]}
                              >
                                <PhoneInput
                                  disabled
                                  country={"ng"}
                                  containerStyle={{ width: "100%" }}
                                  inputStyle={{ height: "3rem", width: "100%" }}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Contact Email"
                                name="contact_email"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input your restaurant contact email!",
                                  },
                                ]}
                              >
                                <Input
                                  disabled
                                  type="email"
                                  style={{ height: "3rem" }}
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                label="Instagram Handle"
                                name="instagram_handle"
                              >
                                <Input
                                  disabled
                                  type="text"
                                  style={{ height: "3rem" }}
                                />
                              </Form.Item>
                            </div>

                            <p className="profile_category_title">
                              Restaurant location
                            </p>
                            <div className="form-flex-2">
                              <Form.Item
                                name="state"
                                label="State"
                                className="form-group"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please fill input is required",
                                  },
                                ]}
                              >
                                <Input disabled style={{ height: "3rem" }} />
                              </Form.Item>
                              <Form.Item
                                name="lga"
                                label="LGA"
                                className="form-group"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please fill input is required",
                                  },
                                ]}
                              >
                                <Input disabled style={{ height: "3rem" }} />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                label="Pickup address"
                                name="address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please fill input is required",
                                  },
                                ]}
                              >
                                <Input
                                  disabled
                                  type="text"
                                  style={{ height: "3rem" }}
                                />
                              </Form.Item>
                            </div>

                            <p className="profile_category_title">
                              Restaurant cooking time
                            </p>
                            <div className="form-flex-2">
                              <Form.Item
                                label="Minimum ready time"
                                name="min_ready_time"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select your minimum ready time!",
                                  },
                                ]}
                              >
                                <TimePicker
                                  disabled
                                  maxLength={5}
                                  size="large"
                                  className="custom-timepicker"
                                  style={{ width: "100%" }}
                                  format={timeFormat}
                                  showNow={false}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Maximum ready time"
                                name="max_ready_time"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select your maximum ready time!",
                                  },
                                ]}
                              >
                                <TimePicker
                                  disabled
                                  maxLength={5}
                                  size="large"
                                  className="custom-timepicker"
                                  style={{ width: "100%" }}
                                  format={timeFormat}
                                  showNow={false}
                                />
                              </Form.Item>
                            </div>

                            <div className="form-flex-2">
                              <Form.Item
                                label="Open time"
                                name="open_time"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select your restaurant open time!",
                                  },
                                ]}
                              >
                                <TimePicker
                                  disabled
                                  maxLength={5}
                                  format={openTimeFormat}
                                  size="large"
                                  showNow={false}
                                  className="custom-timepicker"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                              <Form.Item
                                label="Close time"
                                name="close_time"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select your restaurant close time!",
                                  },
                                ]}
                              >
                                <TimePicker
                                  disabled
                                  maxLength={5}
                                  format={openTimeFormat}
                                  size="large"
                                  showNow={false}
                                  className="custom-timepicker"
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item label="Description" name="description">
                                <Input.TextArea
                                  disabled
                                  placeholder="Enter description here..."
                                  rows={6}
                                  className="custom-textarea"
                                />
                              </Form.Item>
                            </div>
                          </Form>
                          <button
                            className="restaurant_profile_button"
                            onClick={() => setActiveTabKey("2")}
                          >
                            Next
                          </button>
                        </TabPane>
                        <TabPane tab="Kitchen Evaluation" key="2">
                          <div className="p-4 border border-gray-200 rounded-lg bg-gray-50">
                            <h2 className="text-2xl lg:text-3xl font-semibold mb-3">
                              Kitchen Evaluation Page
                            </h2>
                            <p className="mb-4 text-2xl">
                              Thank you for providing your restaurant profile.
                              We aim to satisfy our customers, thus we run a
                              check on your restaurant and kitchen.Please read
                              below and click agree to submit your profile:
                            </p>
                            <ul className="list-disc list-inside mb-4">
                              <li className="text-2xl">
                                We have a cooking area and have uploaded
                                pictures.
                              </li>
                              <li className="text-2xl">
                                We have storage facilities to preserve foods -
                                Fridge and Freezer.
                              </li>
                              <li className="text-2xl">
                                We have the required staff to deliver within our
                                cooking time.
                              </li>
                              <li className="text-2xl">
                                We keep our restaurant clean and follow proper
                                hygiene practices.
                              </li>
                              <li className="text-2xl">
                                Our email is active and will track all
                                notifications.
                              </li>
                            </ul>
                            <p className="font-bold text-2xl lg:text-3xl mt-3 mb-2">
                              Note the following important pickup practices:
                            </p>
                            <ul className="list-disc list-inside mb-4">
                              <li className="text-2xl">
                                You MUST click “Restaurant open” button to start
                                each day.
                              </li>
                              <li className="text-2xl">
                                You MUST click “Restaurant close” button to
                                close each day.
                              </li>
                              <li className="text-2xl">
                                You MUST use a seal tape to seal the food pack.
                              </li>
                              <li className="text-2xl">
                                You MUST paste the order code sticker on the
                                order package.
                              </li>
                            </ul>
                          </div>

                          <Form.Item>
                            <label className="mt-4 mb-3">
                              Cooking Area Images:
                            </label>
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              onPreview={handlePreview}
                              showUploadList={{ showRemoveIcon: false }} // Disable remove option
                              disabled // Prevent interactions
                            />
                            {/* Preview Modal */}
                            {/* {previewImage && (
															<Image
																preview={{
																	visible: previewOpen,
																	src: previewImage,
																	onVisibleChange: (visible) => setPreviewOpen(visible),
																}}
																style={{display: 'none'}} // Keeps Image hidden but triggers preview modal
															/>
														)} */}
                          </Form.Item>
                          <div className="flex items-center my-4">
                            {selectedRestaurant && (
                              <Checkbox
                                checked={
                                  selectedRestaurant?.cooking_area
                                    ? true
                                    : false
                                }
                                disabled={true}
                                className="text-lg"
                              >
                                Agreed to the terms and conditions.
                              </Checkbox>
                            )}
                          </div>
                          <button
                            className="restaurant_profile_button"
                            disabled={fileList.length ? false : true}
                            onClick={() => setActiveTabKey("3")}
                          >
                            Next
                          </button>
                        </TabPane>
                        <TabPane tab="Menu" key="3">
                          {menuTotal === 0 ? (
                            <Empty description="No menu items available. Restaurant Does not have any menu yet."></Empty>
                          ) : (
                            <>
                              <Row gutter={[16, 16]}>
                                {menus.map((menu, index) => (
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={6}
                                    key={index}
                                  >
                                    <FoodCard food={menu} />
                                  </Col>
                                ))}
                              </Row>
                              <Pagination
                                showSizeChanger
                                onShowSizeChange={onShowSizeChange}
                                defaultCurrent={1}
                                total={menuTotal}
                                current={page}
                                pageSize={per_page}
                                onChange={handlePageChange}
                                className="mt-4"
                              />
                            </>
                          )}

                          <div className="mt-5">
                            <button
                              className="restaurant_profile_button"
                              disabled={menus.length ? false : true}
                              onClick={() => setActiveTabKey("4")}
                            >
                              Next
                            </button>
                          </div>
                        </TabPane>
                        <TabPane tab="Support Manager" key="4">
                          <div className="p-4 border border-gray-200 rounded-lg bg-gray-50">
                            <h2 className="text-2xl lg:text-3xl font-semibold mb-3">
                              Support Manager
                            </h2>
                            {selectedSupportManager?.id?.length ? (
                              <div>
                                <p className="text-xl mb-2">
                                  Current Support Manager:{" "}
                                  {`${selectedSupportManager.firstName} ${selectedSupportManager.lastName}`}
                                </p>
                                <p className="text-xl mb-2">
                                  Email:{" "}
                                  {selectedSupportManager.email}
                                </p>
                                <p className="text-xl mb-2">
                                  Phone:{" "}
                                  {selectedSupportManager.phone}
                                </p>
                                {/* <p className="text-xl mb-4">
                                  Address:{" "}
                                  {selectedSupportManager.phone}
                                </p> */}
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    setSelectedRestaurantToggle(true)
                                  }
                                >
                                  Reassign Support Manager
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <p className="text-xl mb-4">
                                  No Support Manager assigned yet.
                                </p>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    setSelectedRestaurantToggle(true)
                                  }
                                >
                                  Assign Support Manager
                                </Button>
                              </div>
                            )}
                            <Modal
                              title="Assign Support Manager"
                              open={selectedRestaurantToggle}
                              onCancel={() =>
                                setSelectedRestaurantToggle(false)
                              }
                              footer={null}
                            >
                              <Form
                                layout="vertical"
                                onFinish={AssignSupportManager}
                              >
                                {assignError && <p>{assignError}</p>}
                                <Form.Item
                                  label="Search Support Managers"
                                  name="search"
                                >
                                  <Input.Search
                                    placeholder="Search by name"
                                    onSearch={SearchSupportManagers}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Select Support Manager"
                                  name="support_manager_id"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select a support manager!",
                                    },
                                  ]}
                                >
                                  <Select value={`${selectedSupportManager.id}----${selectedSupportManager.firstName}----${selectedSupportManager.lastName}----${selectedSupportManager.email}----${selectedSupportManager.phone}`} placeholder="Select a support manager">
                                    {supportManagers.map((manager) => (
                                      <Select.Option
                                        key={manager.id}
                                        value={`${manager.id}----${manager.first_name}----${manager.last_name}----${manager.email}----${manager.phone_number}`}
                                      >
                                        {manager.first_name} {manager.last_name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Assign
                                  </Button>
                                </Form.Item>
                                <Pagination
                                  current={smPage}
                                  pageSize={page_size}
                                  total={total_users}
                                  showTotal={(total, range) =>
                                    `${range[0]}-${range[1]} of ${total} users`
                                  }
                                  onChange={handleSupportManagerPageChange}
                                  showSizeChanger
                                  onShowSizeChange={
                                    handleSupportManagerPageChange
                                  }
                                />
                              </Form>
                            </Modal>

                            {selectedRestaurant?.status === "approved" ? (
                              <div className="mt-4">
                                {approvingRestaurant ? (
                                  <button
                                    className="restaurant_profile_button_disabled"
                                    disabled
                                  >
                                    Suspending restaurant. Please wait
                                    <Spin
                                      style={{ marginLeft: "10px" }}
                                      indicator={newAntIcon}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="restaurant_profile_button"
                                    onClick={SuspendRestaurant}
                                  >
                                    Suspend Restaurant
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="mt-4">
                                {approvingRestaurant ? (
                                  <button
                                    className="restaurant_profile_button_disabled"
                                    disabled
                                  >
                                    Approving restaurant. Please wait
                                    <Spin
                                      style={{ marginLeft: "10px" }}
                                      indicator={newAntIcon}
                                    />
                                  </button>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    {/* {selectedRestaurant?.cooking_area &&
                                      +menuTotal > 0 ? (
                                      <button
                                        className="restaurant_profile_button"
                                        onClick={ApproveRestaurant}
                                      >
                                        Approve Restaurant
                                      </button>
                                    ) : ( */}
                                    <button
                                      className={`${selectedRestaurant?.id?.length ? "restaurant_profile_button" : "restaurant_profile_button_disabled"}`}
                                      onClick={ApproveRestaurant}
                                      disabled={selectedSupportManager?.id?.length ? false : true}
                                    >
                                      Approve Restaurant
                                    </button>
                                    {/* )} */}
                                    {/* {selectedRestaurant?.status !==
                                      "rejected" && (
                                      <button
                                        className="restaurant_profile_button"
                                        onClick={RejectRestaurant}
                                      >
                                        Reject Restaurant
                                      </button>
                                    )} */}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </TabPane>
                      </Tabs>
                    </div>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Spin>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default SelectedRestaurantById;
